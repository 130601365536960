import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
} from '@material-ui/core';
import {
  Divider,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { HowItWorks } from 'components/common';

import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const TermsOfServices = () => {
  const classes = useStyles();
  
  return (
    <Container className={classes.container}>
      <Box>
        <Typography textAlign="left" variant="h3">Terms Of Services</Typography>
        <Divider className={classes.divider} />
        <Typography textAlign="left" variant="body1"><b>Effective Date: july 30, 2024</b></Typography>
        <Typography textAlign="left" variant="body1">Welcome to San Francisco Condo Market, operated by Condo Market, Inc. ("Condo Market"). By using our platform, you agree to these Terms of Service ("Terms"). Please read them carefully.</Typography>
        <ol>
          <li>
            <Typography textAlign="left" variant="body1"><b>Description of Services</b></Typography>
            <Typography textAlign="left" variant="body1">San Francisco Condo Market is a marketing platform that facilitates interactions between potential property buyers and property owners. The platform provides the following functions:</Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  Populates every unit in every building from public records.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Allows buyers to submit bids on any unit in any building.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Enables owners to receive bids and choose to accept, negotiate, or decline.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Lets owners set and publicly display the price they would sell for.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Permits users to create affiliate accounts to distribute listing links and earn affiliate fees for successful purchases through their unique links.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Description of Services</b></Typography>
            <Typography textAlign="left" variant="body1">
              San Francisco Condo Market is not a real estate broker and does not perform any activities requiring a real estate license. All real estate transactions and legal activities are managed by an external broker not affiliated with or employed by San Francisco Condo Market.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>User Accounts</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  Registration: Users must register for an account to use certain features of the platform. You agree to provide accurate, current, and complete information during the registration process.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Affiliate Program</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  Participation: Eligible neighbors can sign up as affiliates to share unique listing links and earn fees based on successful transactions.

                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Prohibited Activities: Affiliates must not engage in activities requiring a real estate license, including but not limited to listing properties, negotiating transactions, or providing real estate advice. Affiliates are representatives of the San Francisco Condo Market platform only.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Broker/Agent Interaction</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  Fees and Invoicing: Brokers/agents pay a variable fee per transaction for access to users on the platform and the affiliate program. Fees are invoiced based on the amount of San Francisco Condo Market resources utilized.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Non-Interaction with Affiliates: Brokers/agents will have no direct interaction, communication, or transactional relationship with affiliates. Affiliates promote their neighbors' property listings, not those of any specific broker or agent.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Indemnification</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  By Brokers/Agents: Brokers/agents agree to indemnify and hold harmless Condo Market, Inc., San Francisco Condo Market, and its officers, directors, employees, and agents from any claims, damages, or legal fees arising from activities conducted by affiliates that may require a real estate license.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  By Affiliates: Affiliates agree to indemnify brokers and agents from any unlawful activities, including but not limited to discussing price and terms with prospective buyers, negotiations, and strategy.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Limitation of Liability</b></Typography>
            <Typography textAlign="left" variant="body1">
              San Francisco Condo Market is not responsible for the actions or omissions of affiliates or any third parties. The platform provides a marketplace for marketing property listings and facilitating interactions but does not guarantee the success or legality of transactions.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Termination</b></Typography>
            <Typography textAlign="left" variant="body1">
              We may terminate or suspend your account and access to the platform at any time, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the platform will immediately cease.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Governing Law</b></Typography>
            <Typography textAlign="left" variant="body1">
              These Terms are governed by and construed in accordance with the laws of the state where Condo Market, Inc. is headquartered, without regard to its conflict of law principles
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Changes to Terms</b></Typography>
            <Typography textAlign="left" variant="body1">
              We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on the platform. Your continued use of the platform after any such changes constitutes your acceptance of the new Terms.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Entire Agreement</b></Typography>
            <Typography textAlign="left" variant="body1">
              These Terms constitute the entire agreement between you and San Francisco Condo Market regarding the use of the platform and supersede all prior agreements or understandings.
            </Typography>
          </li>
        
          <li>
            <Typography textAlign="left" variant="body1"><b>Contact Us</b></Typography>
            <Typography textAlign="left" variant="body1">
              If you have any questions about these Terms, please contact us at:
            </Typography>
            <Typography textAlign="left" variant="body1">
              Condo Market, Inc.
            </Typography>
            <Typography textAlign="left" variant="body1">
              <b>info@sanfranciscocondomarket.com</b>
            </Typography>
          </li>
        </ol>
        <Box className={classes.testimonials}>
          {testimonials.map((item)=> {
            return (
              <Card className={classes.cardTestimonials}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                    <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                    {item.content}
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActionBar}>
                  <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
                </CardActions>
              </Card>    
            )
          })}
        </Box>
        <HowItWorks />
      </Box>
    </Container>
  );
}

export default TermsOfServices

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  // button: {
  //   backgroundColor: 'transparent',
  //   color: theme.palette.common.white,
  //   '&:hover': {
  //     backgroundColor: theme.palette.primary.dark,
  //   },
  // },
  logo: {
    height: 37,
    filter: 'invert(100%) contrast(200%) grayscale(100%)',
    cursor: 'pointer',
  },
  container: {
    padding: 20,
  },
  header: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },
  whiteHeader: {
    backgroundColor: '#ffffff !important',
    color: '#000000 !important'
  },
  stickeyHeader: {
    backgroundColor: "rgba(255, 255, 255, 0.655) !important",
    borderColor: "rgba(255, 255, 255, 0.655) !important",
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  contactUsButton: {
    padding: '8px 24px 7px',
    borderRadius: 100,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 20,
    background: '#000000',
    color: '#ffffff'
  },
  rightNavBtns: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    justifyContent: 'center',
  }
  // Add more styles as needed
}));
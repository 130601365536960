import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

const RedirectIfAuthenticated = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard'); // Redirect to home or any other page
    }
  }, [currentUser, navigate]);

  return null;
};

export default RedirectIfAuthenticated;
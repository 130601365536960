import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  logo: {
    height: 37,
    filter: 'invert(100%) contrast(200%) grayscale(100%)',
  },
  container: {
    padding: 20,
  },
  footer: {
    backgroundColor: '#181e28',
    boxShadow: 'none !important',
    justifyContent: 'center',
    display: 'flex',
    padding: 40,
    marginTop: -30,
    color: "#ffffff",
  },
  img: {
    width: 106,
  },
  footerDivider: {
  	borderColor: "#ffffff !important",
  	marginTop: "50px !important",
  	marginBottom: "50px !important",
  	
  },
  footerHeading: {
    maxWidth: 680,
    marginBottom: 0,
    fontSize: 20,
    fontWeight: '600 !important',
    lineHeight: '32px',
    color: "#ffffff",
  },
  footerLastText: {
  	marginBottom: 0,
    fontSize: 16,
    lineHeight: '26px',
  },
  footerLinks: {
    display: 'flex',
    cursor: 'pointer !important',
  },
  footerlinksParent: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
    },
  }
  // Add more styles as needed
}));
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import {
  Box,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Link,
} from '@material-ui/core';
import {
  TextField,
  Chip,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { Call, ArrowForward } from '@mui/icons-material';
import clsx from 'clsx';
import { BuyImg } from 'assets/images';
import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const Buildings = () => {
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState('');
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetchData = async (query) => {
  	const url = query === '_start_' ? `${process.env.REACT_APP_API_URL}/api/v1/buildings` : `${process.env.REACT_APP_API_URL}/api/v1/buildings?term=${query}`
    const response = await fetch(url);
    const data = await response.json();
    setData(data.buildings);
  };

  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    if (searchInput.length > 0) {
      debouncedFetchData(searchInput);
      fetchData('_start_');
    }
  }, [searchInput]);

  useEffect(() => {
    fetchData('_start_');
  }, []);


  const goToDetails = (item) => {
  	navigate('/building-details', { state: { building: item } });
  }

  return (
    <>
    	<Box className={classes.container}>
    		<img src={BuyImg} alt="Buyer"/>
    	</Box>
    	<Card className={classes.offer}>
        <CardContent className={classes.offerContent}>
          <Box className={classes.navLinks}>
          	<Link href="/" color="inherit">Home</Link>
          	<Typography textAlign="center" variant="body1">
            	/
          	</Typography>
          	<Link href="#" color="inherit" className={classes.activeLink}>Buildings</Link>
          </Box>
          <Typography textAlign="center" variant="h1" className={classes.offerTitle}>
            Make an offer on over 8,000 units across 70 buildings in San Francisco
          </Typography>

          <Typography textAlign="center" variant="body1" className={classes.offerBody}>
            Make an offer on any unit in any building. List your own property without moving out.
          </Typography>
          <Box className={classes.bottomLinks}>
	          <Button
	              component="label"
	              role={undefined}
	              variant="contained"
	              tabIndex={-1}
	              className={classes.callBtn}
	              startIcon={<Call />}
	            >
	            Schedule a call
	          </Button>
	          <Typography textAlign="center" variant="body1">
            	or call
          	</Typography>
          	<Link href="#" color="inherit">+1 415 691 9272</Link>
          </Box>

          <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { marginTop: 20, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
            
          <TextField
            id="outlined-search-input"
            label="Building, address, zip, or neighborhood"
            type="text"
            value={searchInput}
        		onChange={(e) => setSearchInput(e.target.value)}
          />
          </Box>
          <Container style={{padding: 20}}>
          <Grid container spacing={2}>
          	{data.map((item) => {
          		return (
          			<Grid item xs={12} md={4} key={item.id}>
          				<Card className={classes.cardList} onClick={()=>goToDetails(item)}>
	          				<Chip label={item.label} className={classes.label} style={{backgroundColor: 'hsla(129.29577464788733, 48.34%, 28.99%, 1.00)', color: '#ffffff',}}/>
							      <CardMedia
							        style={{ height: 300 }}
							        image={item.photo}
							        title={item.name}
							      />
							      <CardContent>
							        <Box className={classes.boxTitle}>
								        <Typography variant="h6" component="div" color={'#181e28'} className={clsx(classes.cardTextTruncate, classes.cardTitle)}>
								          {item.name}
								        </Typography>
								        <ArrowForward />
							        </Box>
							        <Typography variant="body2" component="p" className={classes.cardTextBody}>
							          {item.price && USDollar.format(item.price)} | {item.floors} floors | {item.units} units
							        </Typography>
							        <Typography variant="body2" component="p" className={classes.cardTextBody}>
							          {item.address}
							        </Typography>
							      </CardContent>
							    </Card>
          			</Grid>
        			)
          	})}
          </Grid>
          </Container>
        </CardContent>
      </Card>

      <Box className={classes.search}>
      	<Container style={{padding: 20, backgroundColor: 'inherit', borderRadius: 15}}>
          <Grid container spacing={2}>
	        </Grid>
        </Container>
      </Box>
      
      <Box className={classes.testimonials}>
        {testimonials.map((item)=> {
          return (
            <Card className={classes.cardTestimonials}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                </Box>
                <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionBar}>
                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
              </CardActions>
            </Card>    
          )
        })}
        
      </Box>
      
    </>
  );
}

export default Buildings

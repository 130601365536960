import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@material-ui/core';
import {
  Divider,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { HowItWorks } from 'components/common';

import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const Sitemap = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const fetchData = async (query) => {
  	const url = `${process.env.REACT_APP_API_URL}/api/v1/buildings`
    const response = await fetch(url);
    const data = await response.json();
    setData(data.buildings);
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
  	<Container className={classes.container}>
	    <Box>
	    	<Typography textAlign="left" variant="h3">Sitemap</Typography>
	    	<Divider />
	    	<Grid container spacing={2} style={{padding: 20}}>
	    		{data.map((item)=> {
	          return (
	        		<Grid item xs={12} md={3}>
	        			<a href={`/building-details/${item.id}`}>{item.name}</a>
	        		</Grid>
	          )
	        })}
    		</Grid>
		  	<Box className={classes.testimonials}>
	        {testimonials.map((item)=> {
	          return (
	            <Card className={classes.cardTestimonials}>
	              <CardContent>
	                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
	                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
	                </Box>
	                <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
	                  {item.content}
	                </Typography>
	              </CardContent>
	              <CardActions className={classes.cardActionBar}>
	                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
	              </CardActions>
	            </Card>    
	          )
	        })}
	      </Box>
	      <HowItWorks />
    	</Box>
    </Container>
  );
}

export default Sitemap

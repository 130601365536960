import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  container: {
    padding: 0,
  },
  loader: {
		backgroundColor: 'rgba(24, 30, 40, .8)',
		top: 0,
		right:0,
		left:0,
		bottom:0,
		position: 'absolute',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	loaderText: {
		color: '#ffffff',
	},
}));
import { makeStyles } from '@material-ui/core/styles';
import { HowItWorks, BackgroundImg } from 'assets/images';
export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `linear-gradient(to right, rgba(24, 30, 40, .6), rgba(0, 0, 0, 0) 50%), linear-gradient(to top, rgba(0, 0, 0, .6), rgba(0, 0, 0, 0) 15%), linear-gradient(rgba(0, 0, 0, .25), rgba(0, 0, 0, .25)), url("${BackgroundImg}")`,
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    right: 0,
    minHeight: 840,
    backgroundSize: 'cover',
    zIndex: -1,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  heading: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },
  },
  headingContainer: {
    width: '60%',
    color: '#ffffff',
    marginTop: 160,
    padding: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 40,
    },
  },
  subheading: {
    marginBottom: 0,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '96px',
    [theme.breakpoints.down('md')]: {
      lineHeight: '40px',
      marginTop: 20,
      marginBottom: 20,
    },
  },
  searchButton: {
    padding: '16px 32px',
    borderRadius: 100,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: 16,
    background: '#ffffff',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  hiwButton: {
    padding: '16px 32px',
    borderRadius: 100,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 20,
    background: '#000000',
    color: '#ffffff'
  },
  howitworks: {
    backgroundImage: `url("${HowItWorks}")`,
    minHeight: 200,
    backgroundSize: '100%',
    marginLeft: -15,
    marginRight: -15,
    marginTop: 190,
    paddingTop: 30,
    paddingBottom: 30,
  },
  hiwHeading: {
    marginBottom: 0,
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '40px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  hiwSubHeadings: {
    flex: '0 auto',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    marginBottom: 10
  },
  hiwBody: {
    flex: '0 auto',
    fontSize: 16,
    lineHeight: '26px',
    color: '#576d8e',
  },
  hiwLearnMore: {
    textTransform: 'capitalize',
    letterSpacing: '.2px',
    marginBottom: 0,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    textDecoration: 'underline'
  },
  cardHiw: {
    minHeight: 200,
    position: 'relative',
  },
  testimonials: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    background: '#ffffff',
    padding: '80px 20px',
    marginLeft: -15,
    marginRight: -15,
    overflowX: 'scroll',
  },
  cardTestimonials: {
    minWidth: 300,
    height: 200,
    position: 'relative',
  },
  testimonialsBody: {
    flex: '0 auto',
    fontSize: 16,
    lineHeight: '26px',
    color: '#576d8e',
  },
  cardActionBar: {
    position: 'absolute',
    bottom: 0,
  },
}));
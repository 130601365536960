import { makeStyles } from '@material-ui/core/styles';
import { HowItWorks, SellFooterImg } from 'assets/images';
export const useStyles = makeStyles((theme) => ({
	offer: {
		backgroundColor: '#ffffff',
    position: 'relative',
    margin: 32,
    borderRadius: 20,
    boxShadow: 'none',
	},
	offerTitle:{
		marginBottom: 0,
		fontSize: 40,
		fontWeight: 700,
		lineHeight: '46px',
	},
	subOfferTitle:{
		marginBottom: 0,
		fontSize: 32,
		fontWeight: 700,
		lineHeight: '86px',
	},
	offerContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: 24,
		padding: 40,
	},
	offerBody: {
    maxWidth: 680,
    marginBottom: 0,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '64px',
	},
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    marginBottom: 0,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '96px',
  },
  searchButton: {
    padding: '16px 32px',
    borderRadius: 100,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: 16,
    background: '#ffffff',
  },
  testimonials: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    background: '#ffffff',
    padding: '80px 20px',
    marginLeft: -15,
    marginRight: -15,
    overflowX: 'scroll',
  },
  cardTestimonials: {
    minWidth: 300,
    height: 200,
    position: 'relative',
  },
  testimonialsBody: {
    flex: '0 auto',
    fontSize: 16,
    lineHeight: '26px',
    color: '#576d8e',
  },
  cardActionBar: {
    position: 'absolute',
    bottom: 0,
  },
  navLinks: {
  	display: 'flex',
  	flexDirection: 'row',
  	gap: 10,
  	marginBottom: 15,
  	'& a': {
  		textDecoration: 'none',
  		fontSize: 14,
  		'&:hover': {
  			textDecoration: 'none',
  		}
  	},
    '& .MuiTypography-body1': {
      lineHeight: 'normal',
    },
  },
  activeLink: {
  	fontWeight: 'bold',
  },
  inlineItems: {
  	display: 'flex',
  	flexDirection: 'row',
  	gap: 30,
  },
  image: {
  	width: '100%',
  	borderRadius: 10,
  },
}));
import { makeStyles } from '@material-ui/core/styles';
import { BuyFooterImg } from 'assets/images';
export const useStyles = makeStyles((theme) => ({
	container: {
    zIndex: -5,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '100%',
    minHeight: 500,
    maxHeight: 500,
    marginBottom: -112,
    display: 'flex',
    overflow: 'hidden',
    '& img': {
    	objectFit: 'cover',
    	width: '100%',
    }
	},
	offer: {
		backgroundColor: '#ffffff',
    position: 'relative',
    margin: 32,
    borderRadius: 20,
    boxShadow: 'none',
	},
	offerTitle:{
		marginBottom: 0,
		fontSize: 40,
		fontWeight: 700,
		lineHeight: '46px',
	},
	offerContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: 24,
		padding: 40,
	},
	offerBody: {
    maxWidth: 680,
    marginBottom: 0,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px',
	},
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    marginBottom: 0,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '96px',
  },
  searchButton: {
    padding: '16px 32px',
    borderRadius: 100,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: 16,
    background: '#ffffff',
    width: '40%'
  },
  testimonials: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    background: '#ffffff',
    padding: '80px 20px',
    marginLeft: -15,
    marginRight: -15,
    overflowX: 'scroll',
  },
  cardTestimonials: {
    minWidth: 300,
    height: 200,
    position: 'relative',
  },
  testimonialsBody: {
    flex: '0 auto',
    fontSize: 16,
    lineHeight: '26px',
    color: '#576d8e',
  },
  cardActionBar: {
    position: 'absolute',
    bottom: 0,
  },
  navLinks: {
  	display: 'flex',
  	flexDirection: 'row',
  	gap: 10,
  	marginBottom: 15,
  	'& a': {
  		textDecoration: 'none',
  		fontSize: 14,
  		'&:hover': {
  			textDecoration: 'none',
  		}
  	},
  	'& .MuiTypography-body1': {
  		lineHeight: 'normal',
  	}
  },
  activeLink: {
  	fontWeight: 'bold',
  },
  feedsStepsHeading: {
  	marginBottom: 0,
		fontSize: 40,
		fontWeight: 700,
		lineHeight: '46px',
  },
  feedsStepsDivider: {
  	marginTop: '30px !important',
  	marginBottom: '50px !important',
  },
  feedsStepsKey: {
  	fontWeight: 700,
    lineHeight: '136px',
    color: '#576d8e',
    fontSize: 120,
  },
  feedsStepsTitle: {
    flex: '0 auto',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
  },
  feedsStepsBody: {
    flex: 'none',
    fontSize: 16,
    lineHeight: '26px',
  },
  feedsStepsBodyParent: {
  	display: 'flex',
  	flexDirection: 'column',
  	gap: 10,
  },
  propertyContactUs:{
  	backgroundColor: '#ffffff',
  	marginLeft: -15,
  	marginRight: -15,
  	paddingTop: 20
  },
  propertyContactUsForm: {
  	backgroundColor: '#ffffff',
  	padding: 20,
  	minHeight: 200,
  	borderRadius: 10,
  },
  propertyContactusLink: {
  	backgroundImage: `url("${BuyFooterImg}")`,
    minHeight: 200,
    width: '100%',
    padding: 0,
    position: 'relative',
    objectFit: 'cover',
    borderRadius: 10,
    backgroundPosition: '50%',
    backgroundSize: 'cover',
  },

  searchButtonParent: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0',
  },

}));
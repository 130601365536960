import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  TextField,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  MenuItem,
  Select,
  Rating,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment
} from '@mui/material';
import moment from 'moment'
import Carousel from 'react-material-ui-carousel';
import { ArrowForward, Add, Check, ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Bed, BathRoom, UnitArea } from 'assets/images';
import { AuthContext } from 'AuthProvider';
import { Loader } from 'components/common';
import clsx from 'clsx';
import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const links = ['Facts', 'Pictures', 'Buy or Sell', 'Contact']
const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const BuildingDetails = () => {
  const { currentUser, token } = useContext(AuthContext);
	const [buildingDetails, setBuildingDetails] = useState({});
	const [buildingUnits, setBuildingUnits] = useState([]);
	const [selectedBuilding, setBuilding] = useState({});
	const [unit, setUnit] = useState({});
	const classes = useStyles();
	const location = useLocation();
  let { building } = location.state || {};
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [buyingValue, setBuyingValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dailog, setDailog] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [linkToCopy, setLinkToCopy] = useState('');
  
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    unitId: '',
    price: ''
  });

  const [sellCondoformData, setSellCondoformData] = useState({
    email: '',
    name: '',
    bathrooms: '',
    bedrooms: '',
    area: '',
    price: ''
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  const fetchData = () => {
    fetchDataWithId(selectedBuilding.id)
  };

  useEffect(() => {
    if (selectedBuilding.id != null) {
      fetchData();
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (building !== {} && building!= undefined) {
      setBuilding(building)
    }
  }, [building]);

  useEffect(() => {
    if (id != null && id!= undefined) fetchDataWithId(id);
    
  }, [id]);

  const handleChangeUnit = (event) => {
  	setUnit(event.target);
  }

  const handleChangeUnitSell = (event) => {
    setUnit(event.target);
    const unitInfo = buildingUnits.find((item)=> item.id == event.target.value)
    setSellCondoformData({
      ...sellCondoformData,
      area: unitInfo.area,
      bathrooms: unitInfo.baths,
      bedrooms: unitInfo.bedrooms,
      building_unit_id: unitInfo.id
    });
  }

  
  const handleDialogClose = () => {
    setDailog(!dailog);
  }

  const submitAnOffer = (item) => {
  	setUnit(item);
  	setBuyingValue(1);
  }

  const fetchDataWithId = async(buildingId) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/buildings/${buildingId}`
    const response = await fetch(url);
    const data = await response.json();
    setBuildingDetails(data.building);
    building = data.building
    setBuildingUnits(data.units)
  }

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSellCondoChangeForm = (e) => {
    const { name, value } = e.target;
    setSellCondoformData({
      ...sellCondoformData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    const data ={...formData, building_unit_id: unit.value}
    const url = `${process.env.REACT_APP_API_URL}/api/v1/condos/buy`
    await apiCall(data, url);
  };


  const handleSellCondoSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    const unitInfo = buildingUnits.find((item)=> item.id == unit?.value)

    const data ={...sellCondoformData, building_id: unitInfo.building_id };
    data.address = unitInfo.unit_name

    const url = `${process.env.REACT_APP_API_URL}/api/v1/condos/sell`
    await apiCall(data, url);
  };

  const generateAffiliateLink = async(item) => {
    setLoading(true);
    const data ={base_link: `${process.env.REACT_APP_URL}/building-details/${selectedBuilding.id}/${item.id}`, unit_id: item.id}
    const url = `${process.env.REACT_APP_API_URL}/api/v1/affiliates/generate`
    await apiCall(data, url, true, 'Successfully generated the link');
  };
  
  const successStatus = async(e) => {
    setSuccess(true);
    setFormData({
      email: '',
      name: '',
      phone: '',
      address: '',
      price: ''
    })
    setSellCondoformData({
      email: '',
      name: '',
      phone: '',
      address: '',
      bathrooms: '',
      bedrooms: '',
      area: '',
      price: ''
    })
    setUnit({});
    setTimeout(function(){
      setSuccess(false);
    },1500)
  }

  const apiCall = async(data,url,header=false, message=false) => {
    setLoading(true);
    const headers = {
      'Content-Type': 'application/json',
    }
    if (header) headers['Authorization'] = token

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setUnit({});
      successStatus();
      if(header) {
        setDailog(true);
        setLinkToCopy(result.affiliate_link);
      }

    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const ScrollToUnits = () => {
    document.getElementById('element')?.scrollIntoView({ behavior: 'smooth' });
  }


  const copyToClipboard = () => {
    navigator.clipboard.writeText(linkToCopy).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    }, (err) => {
      setCopySuccess('Failed to copy!');
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <Box style={{backgroundColor: '#ffffff', padding: 50, paddingTop: 10}}>
      <Box className={classes.container}>
    		{ buildingDetails.pictures?.length > 0 ? <Carousel
    			navButtonsAlwaysVisible={true}
    			autoPlay={false}
    			animation='slide'
    			className={classes.carImgList}
    			 indicatorContainerProps={{
				    style: {
				      zIndex: 1,
				      marginTop: "0px",
				      position: "relative"
				    }
				  	}}
    			>
    			{buildingDetails.pictures.map((item, index)=> {
	          return (
	          	<Paper>
              	<img src={item} className={classes.imgCarList} alt={building?.name} />
              </Paper>
	          )
	        })}
    		</Carousel> : <img src={building?.photo} alt={building?.name} />}
    	</Box>
    	<Box sx={{mt: 2, gap: 10, display: 'flex'}}>
    		{links.map((link)=> {
          return (
            <Chip label={link} className={classes.label} variant="outlined"  />
          )
        })}
    	</Box>
    	<Grid container spacing={2}>
  			<Grid item xs={12} md={8}>
  				<Typography variant="h6" className={classes.titles}>
            {buildingDetails.location}
          </Typography>
          <Typography variant="h4" className={classes.titlesName}>
            {buildingDetails.building_name}
          </Typography>
          <Typography variant="h5" className={classes.titles}>
            Starting at {selectedBuilding.price && USDollar.format(selectedBuilding.price)}
          </Typography>
          <Divider className={classes.dividerGap}/>
          <Grid container spacing={2}>
						{buildingDetails.built_in && (
							<Grid item xs={12} md={6} style={{marginTop: 15}}>
								<Typography variant="h6">
									Built in {buildingDetails.built_in}
								</Typography>
							</Grid>
						)}
						{buildingDetails.floors && (
							<Grid item xs={12} md={6} style={{marginTop: 15}}>
								<Typography variant="h6">
									Building floors: {buildingDetails.floors}
								</Typography>
							</Grid>
						)}
						{buildingDetails.building_type && (
							<Grid item xs={12} md={6} style={{marginTop: 15}}>
								<Typography variant="h6">
									{buildingDetails.building_type}
								</Typography>
							</Grid>
						)}
						{buildingDetails.units && (
							<Grid item xs={12} md={6} style={{marginTop: 15}}>
								<Typography variant="h6">
									Number of Units: {buildingDetails.units}
								</Typography>
							</Grid>
						)}
						{buildingDetails.parking && (
							<Grid item xs={12} md={6} style={{marginTop: 15}}>
								<Typography variant="h6">
									{buildingDetails.parking}
								</Typography>
							</Grid>
						)}
						{buildingDetails.beds && (
							<Grid item xs={12} md={6} style={{marginTop: 15}}>
								<Typography variant="h6">
									{buildingDetails.beds}
								</Typography>
							</Grid>
						)}
						{buildingDetails.status && (
							<Grid item xs={12} md={6}>
								<Typography variant="h6">
									Status: {buildingDetails.status}
								</Typography>
							</Grid>
						)}
          </Grid>
          <Divider className={classes.dividerGap}/>
          <Box dangerouslySetInnerHTML={{__html: buildingDetails.about}} className={classes.aboutProperty}/>
          {buildingDetails.amenities && (
          	<Accordion elevation={0}>
			        <AccordionSummary
			          expandIcon={<Add />}
			          aria-controls="panel1-content"
			          id="panel1-header"
			        >
			          <Typography variant="h6"><b>Amenities</b></Typography>
			        </AccordionSummary>
			        <AccordionDetails>
			          <Box dangerouslySetInnerHTML={{__html: buildingDetails.amenities}} className={classes.amenitiesProperty} />
			        </AccordionDetails>
		      	</Accordion>
		      )}
  			</Grid>
  			<Grid item xs={12} md={4}>
  				<Card>
  					<Grid container>
  						<Grid item xs={4} md={4}>
  							<img src="https://assets-global.website-files.com/6566cb43925080ed789f6ec7/65cd76bffb8d389db4b77b7a_tim-mcmullen-head-shot.png" style={{width: '100%'}} alt="Author" />
  						</Grid>
  						<Grid item xs={8} md={8}>
  						</Grid>
  					</Grid>
  				</Card>
  				<Card style={{marginTop: 20,padding: 10}}>
  					<Grid container style={{display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
  						<Typography variant="h5"><b>There are units available!</b></Typography>
  						<Typography variant="body1">Make an offer, or name your price to sell</Typography>
  						<Button className={classes.viewAllunits} onClick={()=> ScrollToUnits()}>View all units</Button>
  					</Grid>
  				</Card>
  			</Grid>
  			{buildingDetails.pictures && (
  				<Grid item xs={12} md={12}>
	  				<Typography variant="h6" style={{marginBottom: 5}}><b>Pictures</b></Typography>
	  				<Divider style={{marginBottom: 20}}/>
	  				<Grid container spacing={1}>
		  				{buildingDetails.pictures.slice(0, 6).map((item, index)=> {
			          return (
			          	<Grid item xs={12} md={4}>
				            <Card className={clsx(classes.cardTestimonials, classes.pointer)} key={`item-${index}`}>
				            	{index === 5 && <Box className={classes.seeAllPhotos}>
				            		<Typography variant="body1" className={classes.seeAllPhotosText}><b>See all photos</b></Typography>
				            		<ArrowForward className={classes.seeAllPhotosText} />
				            	</Box>}
				              <img src={item} style={{objectFit: 'cover', width: '100%'}}/>
				            </Card>
			            </Grid>
			          )
			        })}
		        </Grid>
  				</Grid>
  			)}
				<Grid item xs={12} md={12}>
  				<Divider style={{marginTop: 20, marginBottom: 20}}/>
  			</Grid>
  			<Grid item xs={12} md={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
  				<Typography variant="h4" style={{marginBottom: 25}} id="element"><b>Every unit is for sale for the right price.</b></Typography>
  				<Typography variant="body1">
  					Buyers can write an offer on <i>any</i> unit in the building.
  				</Typography>
  				<Typography variant="body1">
  					Owners can set their own price get notified when buyers are active.
  				</Typography>
  			</Grid>

			<AppBar position="relative" style={{zIndex: 'inherit', border: '1px solid #ccc', boxShadow: 'none', borderTopRightRadius: 10, borderTopLeftRadius: 10}}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.tabs}>
          <Tab label="Buy a condo" {...a11yProps(0)} className={classes.tabItem} />
          <Tab label="Sell a condo" {...a11yProps(1)} className={classes.tabItem}  />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabListItem}>
        {loading && <Loader />}
        {success && <Alert icon={<Check fontSize="inherit" />} severity="success">
          Successfully sent the request
        </Alert>}
        <Typography variant="h5" style={{marginBottom: 25, textAlign: 'center'}}><b>Submit an offer on any unit in the building</b></Typography>
        <Box style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 20, marginBottom: 10}}>
        	<Button onClick={()=>setBuyingValue(0)} className={buyingValue === 0 ? classes.activeTab : classes.buyingitemTab}>1. Select Unit</Button>
        	<Button onClick={()=>setBuyingValue(1)} className={buyingValue === 1 ? classes.activeTab : classes.buyingitemTab}>2. Submit an Offer</Button>
        </Box>
        <Divider style={{width: '100%', marginBottom: 30}} />
        {
        	buyingValue === 0 && <Box style={{position: 'relative'}}>
        		<Grid item xs={12} md={12}>
		  				<Grid container spacing={1}>
			  				{buildingUnits.map((item, index)=> {
				          return (
				          	<Grid item xs={12} md={4}>
					            <Card className={clsx(classes.pointer, classes.units)} key={`item-${item.id}`}>
					            		<Typography variant="h5" className={classes.unitName}>
                  					{item.unit_name}
                					</Typography>
                					<Box style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 20, marginBottom: 10, marginTop: 10}}>
                						{item.bedrooms && <Box className={classes.unitFig}>
	                							<img src={Bed} alt="Bed" className={classes.itemFig} />
	                							<Typography variant="body1" className={classes.itemFigText}>
	                								{item.bedrooms}
	                							</Typography>
                							</Box>
                						}
                						{item.baths && <Box className={classes.unitFig}>
	                							<img src={BathRoom} alt="BathRoom" className={classes.itemFig} />
	                							<Typography variant="body1" className={classes.itemFigText}>
	                								{item.baths}
	                							</Typography>
                							</Box>
                						}
                						{item.area && <Box className={classes.unitFig}>
	                							<img src={UnitArea} alt="UnitArea" className={classes.itemFig} />
	                							<Typography variant="body1" className={classes.itemFigText}>
	                								{item.area}
	                							</Typography>
                							</Box>
                						}
                					</Box>
                					<Divider style={{width: '100%', marginBottom: 15, marginTop: 20}} />
													{item.by_owner ? <>
                            <Typography variant="body2" className={classes.lastsoldprice}>
                              Owner's selling price
                            </Typography>
                            <Typography variant="h5" className={clsx(classes.unitValue, classes.ownerPrice)}>
                              {USDollar.format(item.owner_price)}
                            </Typography>
                            <Typography variant="body1" className={classes.unitValuePrice}>
                              {moment(item.updated_at).format('D/M/YYYY')}
                            </Typography>
                          </> : <>
                            <Typography variant="body2" className={classes.lastsoldprice}>
              								Last sold price & date
              							</Typography>
                  					<Typography variant="h5" className={classes.unitValue}>
              								{item.last_sale_value || "NA" }
              							</Typography>

              							<Typography variant="body1" className={classes.unitValuePrice}>
              								{item.last_settle_date || "NA" }
              							</Typography>
                          </>}
            							<Button
            								variant="outlined"
            								color="primary"
            								style={{width: '100%', marginTop: 20}}
            								onClick={()=>submitAnOffer(item)}
            								>
        										Submit an offer
      										</Button>

                          {currentUser &&<Button
                            variant="contained"
                            color="primary"
                            style={{width: '100%', marginTop: 20}}
                            onClick={()=>generateAffiliateLink(item)}
                            >
                            Generate Link
                          </Button>}
					            </Card>
				            </Grid>
				          )
				        })}
			        </Grid>
	  				</Grid>
        	</Box>
        }

        {
        	buyingValue === 1 && <Box style={{position: 'relative'}}>
			  				<Box className={classes.sellerForm}>
					        <Box
					          component="form"
					          sx={{
					            '& .MuiTextField-root': { marginTop: 20, width: '100%' },
					          }}
					          noValidate
					          autoComplete="off"
					        >
					            
					          <TextField
		                  required
		                  fullWidth
		                  name="name"
		                  label="Name"
		                  type="text"
		                  id="sname"
		                  onChange={handleChangeForm}
		                  value={formData.name}
		                  autoComplete="name"
		                />

					          <TextField
		                  required
		                  fullWidth
		                  name="email"
		                  label="Email"
		                  type="email"
		                  id="semail"
		                  onChange={handleChangeForm}
		                  value={formData.email}
		                  autoComplete="email"
		                />

					          <Select
									    labelId="unit-simple-select-label"
									    id="unit-simple-select"
									    value={unit.value}
									    label="Select Unit"
									    onChange={handleChangeUnit}
									    style={{width: '100%', marginTop: 20}}
									  >
									  	{buildingUnits.map((item, index)=> {
									  		return (
									  			<MenuItem value={item.id} label={item.unit_name}>{item.unit_name}</MenuItem>
									  		)
									  	})}
									    
									  </Select>

					          <TextField
		                  required
		                  fullWidth
		                  name="price"
		                  label="Submit an offer"
		                  type="number"
		                  id="sprice"
		                  onChange={handleChangeForm}
		                  value={formData.price}
		                  autoComplete="selling-price"
		                />
					        </Box>
					        <Box
					          component="form"
					          noValidate
					          autoComplete="off"
					          
					        >
					        <Button variant="contained" className={classes.formButton} onClick={handleSubmit}>Place bid</Button>
					        </Box>
					      </Box>
        	</Box>
        }
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabListItem}>
      {loading && <Loader />}
        <Box className={classes.sellerForm}>
        <Typography textAlign="center" variant="h3" className={classes.sellerFormTitle}>
          How much would you sell your condo for?
        </Typography>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { marginTop: 20, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
            
          <TextField
              required
              fullWidth
              name="name"
              label="Name"
              type="text"
              id="cname"
              onChange={handleSellCondoChangeForm}
              value={sellCondoformData.name}
              autoComplete="name"
            />

	          <TextField
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="cemail"
              onChange={handleSellCondoChangeForm}
              value={sellCondoformData.email}
              autoComplete="email"
            />

            <Select
              labelId="unit-simple-select-label"
              id="unit-simple-select"
              value={unit.value}
              label="Apartment #"
              onChange={handleChangeUnitSell}
              style={{width: '100%', marginTop: 20}}
            >
              {buildingUnits.map((item, index)=> {
                return (
                  <MenuItem value={item.id} label={item.unit_name}>{item.unit_name}</MenuItem>
                )
              })}
              
            </Select>

            <TextField
              fullWidth
              name="area"
              label="Area in sq.ft."
              type="number"
              id="carea"
              onChange={handleSellCondoChangeForm}
              value={sellCondoformData.area}
              autoComplete="off"
            />

            <TextField
              fullWidth
              name="bedrooms"
              label="Bedrooms"
              type="number"
              id="cbedrooms"
              onChange={handleSellCondoChangeForm}
              value={sellCondoformData.bedrooms}
              autoComplete="off"
            />

            <TextField
              fullWidth
              name="bathrooms"
              label="Bathrooms"
              type="number"
              id="cbathrooms"
              onChange={handleSellCondoChangeForm}
              value={sellCondoformData.bathrooms}
              autoComplete="off"
            />

	          <TextField
              required
              fullWidth
              name="price"
              label="Your selling price"
              type="number"
              id="cprice"
              onChange={handleSellCondoChangeForm}
              value={sellCondoformData.price}
              autoComplete="selling-price"
            />

        </Box>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          
        >
        <Button variant="contained" className={classes.formButton} onClick={handleSellCondoSubmit}> sell my unit</Button>
        </Box>
      </Box>
      </TabPanel>
			</Grid>

      <Box className={classes.testimonials}>
        {testimonials.map((item)=> {
          return (
            <Card className={classes.cardTestimonials} key={item.name}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                </Box>
                <Typography variant="body1" className={classes.testimonialsBody}>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionBar}>
                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
              </CardActions>
            </Card>    
          )
        })}
      </Box>
       <Dialog
        open={dailog}
        fullWidth
        maxWidth="md"
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Your Affiliate link for this property
        </DialogTitle>
        <DialogContent >
          <div>
              <p>You can copy and share the link with friends and family or associates. And if any lead gets generated from the link you will generate commission</p>
              <TextField
                label="Copy Text"
                value={linkToCopy}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={copyToClipboard}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />
              {copySuccess && <span style={{ marginLeft: '10px', color: 'green' }}>{copySuccess}</span>}
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default BuildingDetails
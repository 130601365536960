import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
} from '@material-ui/core';
import {
  Divider,
  Alert,
  TextField,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { FiberManualRecord, Check, Search } from '@mui/icons-material';
import { SellImg } from 'assets/images';
import { Loader, HowItWorks } from 'components/common';

import { useStyles } from './styles';
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const feedsSteps = [
	{
		key: '01',
		title: 'Select a building',
		body: "Search buildings for local favorites. If you cant find the building you're looking for - send us a text and we'll add it to our list."
	},
	{
		key: '02',
		title: 'Set your price',
		body: "Owners can set the price they would sell their unit for, even if they just bought it. Once your price is set, decide if you want to create a private listing on the marketplace."
	},
	{
		key: '03',
		title: 'Receive offers',
		body: "Owners receive a unique link to every offer on their property with the ability to Accept, Negotiate or Reject. Choose to receive professional advice on negotiation strategy, or don't"
	},
	{
		key: '04',
		title: 'Inspections & escrow',
		body: "All site-unseen offers are subject to an inspection period. A date will be set to conduct inspections, then ratify the contract, or renegotiate terms. Escrow is professionally managed by a 3rd party."
	}
]
const Sell = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    phone: '',
    address: '',
    price: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/condos/sell`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      // setData(result);
      successStatus()
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  
  const successStatus = async(e) => {
    setSuccess(true);
    setFormData({
      email: '',
      name: '',
      phone: '',
      address: '',
      price: ''
    })
    setTimeout(function(){
      setSuccess(false);
    },1500)
  }
  return (
    <>
    	<Box className={classes.container}>
    		<img src={SellImg} alt="Seller"/>
    	</Box>
    	<Card className={classes.offer}>
        <CardContent className={classes.offerContent}>
          <Box className={classes.navLinks}>
          	<Link href="/" color="inherit">Home</Link>
          	<Typography textAlign="center" variant="body1">
            	/
          	</Typography>
          	<Link href="#" color="inherit" className={classes.activeLink}>Sell</Link>
          </Box>
          <Typography textAlign="center" variant="h1" className={classes.offerTitle}>
            List your own property for a price you would sell for
          </Typography>
          <Typography textAlign="center" variant="body1" className={classes.offerBody}>
            Owners have the power to set their price and create their own listings, all from the comfort of their couch. The Condo Market removes all hinderances to receiving an offer you would sell your unit for, while smoothly conducting the sale and escrow process efficiently and professionally.
          </Typography>
        </CardContent>
      </Card>

      <Box className={classes.feedsStepsParent}>
      	<Container style={{padding: 20}}>
          <Typography textAlign="center" variant="h3" className={classes.feedsStepsHeading}>
            How It Works
          </Typography>
          <Divider className={classes.feedsStepsDivider} />
          <br/>
          <Grid container spacing={2}>
		        {feedsSteps.map((item)=> {
		          return (
		            <Grid item xs={12} md={3}>
			            <Box className={classes.feedsSteps}>
			              <Box className={classes.feedsStepsBodyParent}>
			                <Typography textAlign="center" variant="h1" className={classes.feedsStepsKey}>
			                  {item.key}<FiberManualRecord />
			                </Typography>
			                <Typography textAlign="center" variant="h3" className={classes.feedsStepsTitle}>
			                  {item.title}
			                </Typography>
			                <Typography textAlign="center" variant="body1" className={classes.feedsStepsBody}>
			                  {item.body}
			                </Typography>
			              </Box>
			            </Box>
		            </Grid>
		          )
		        })}
	        </Grid>
        </Container>
      </Box>
      <Box className={classes.searchButtonParent}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          onClick={()=> navigate('/search-building')}
          className={classes.searchButton}
          startIcon={<Search />}
        >
          Search buildings
        </Button>
      </Box>
      
      <Box className={classes.testimonials}>
        {testimonials.map((item)=> {
          return (
            <Card className={classes.cardTestimonials}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                </Box>
                <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionBar}>
                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
              </CardActions>
            </Card>    
          )
        })}
        
      </Box>
      <HowItWorks />
      
    </>
  );
}

export default Sell

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardActions,
  CardContent,
  Grid,
  Button,
} from '@material-ui/core';
import {
  Container,
} from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import { useStyles } from './styles'; // Adjust the path as necessary

const HowItWorks = ({ children }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.howitworks}>
        <Container style={{padding: 20}}>
          <Typography textAlign="center" variant="h3" className={classes.hiwHeading}>
            How It Works
          </Typography>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card className={classes.cardHiw}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography textAlign="center" variant="h4" className={classes.hiwSubHeadings}>
                      Bid on any unit
                    </Typography>
                    <ArrowRightAlt />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.hiwBody}>
                    Submit a bid on any unit you want, whether it's listed or not. Owners receive offers and negotiate.
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActionBar}>
                  <Button size="small" className={classes.hiwLearnMore} onClick={()=> navigate('/search-building')}>Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={classes.cardHiw}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography textAlign="center" variant="h4" className={classes.hiwSubHeadings}>
                      List your own property
                    </Typography>
                    <ArrowRightAlt />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.hiwBody}>
                    Set the price you would sell for without moving out, and receive offers directly.
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActionBar}>
                  <Button size="small" className={classes.hiwLearnMore} onClick={()=> navigate('/search-building')}>Learn More</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card className={classes.cardHiw}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography textAlign="center" variant="h4" className={classes.hiwSubHeadings}>
                      Become an affiliate
                    </Typography>
                    <ArrowRightAlt />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.hiwBody}>
                    Earn from distributing properties on the Condo Market.
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActionBar}>
                  <Button size="small" className={classes.hiwLearnMore} onClick={()=> navigate('/search-building')}>Learn More</Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </Box>
  );
};

export default HowItWorks;

import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
} from '@material-ui/core';
import {
  Divider,
  TextField,
} from '@mui/material';
import Rating from '@mui/material/Rating';
// import { ArrowRightAlt, FiberManualRecord } from '@mui/icons-material';
import { BackgroundImg } from 'assets/images';

import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const feedsSteps = [
	{
		key: '01',
		title: 'Select a building',
		body: "Search buildings for local favorites. If you cant find the building you're looking for - send us a text and we'll add it to our list."
	},
	{
		key: '02',
		title: 'Set your price',
		body: "Owners can set the price they would sell their unit for, even if they just bought it. Once your price is set, decide if you want to create a private listing on the marketplace."
	},
	{
		key: '03',
		title: 'Receive offers',
		body: "Owners receive a unique link to every offer on their property with the ability to Accept, Negotiate or Reject. Choose to receive professional advice on negotiation strategy, or don't"
	},
	{
		key: '04',
		title: 'Inspections & escrow',
		body: "All site-unseen offers are subject to an inspection period. A date will be set to conduct inspections, then ratify the contract, or renegotiate terms. Escrow is professionally managed by a 3rd party."
	}
]
const ContactUs = () => {
  const classes = useStyles();
  return (
    <>
    	<Card className={classes.offer}>
        <CardContent className={classes.offerContent}>
          <Box className={classes.navLinks}>
          	<Link href="/" color="inherit">Home</Link>
          	<Typography textAlign="center" variant="body1">
            	/
          	</Typography>
          	<Link href="#" color="inherit" className={classes.activeLink}>Contact</Link>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
		          <Typography textAlign="center" variant="h1" className={classes.offerTitle}>
		            Contact Us
		          </Typography>
		          <Typography textAlign="center" variant="h2" className={classes.subOfferTitle}>
		            The Condo Market
		          </Typography>
		          <Box className={classes.inlineItems}>
			          <Typography textAlign="center" variant="h2" className={classes.offerBody}>
			            +1 415 691 9272
			          </Typography>
			          <Typography textAlign="center" variant="h2" className={classes.offerBody}>
			            tim@mcmullen.properties
			          </Typography>
		          </Box>
	          </Grid>
	          <Grid item xs={12} md={6}>
	          	<img src={BackgroundImg} alt="contact" className={classes.image}/>
	          </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <Box className={classes.testimonials}>
        {testimonials.map((item)=> {
          return (
            <Card className={classes.cardTestimonials}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                </Box>
                <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionBar}>
                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
              </CardActions>
            </Card>    
          )
        })}
        
      </Box>

      
    </>
  );
}

export default ContactUs

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@material-ui/core';
import Rating from '@mui/material/Rating';
import { Search, ArrowRightAlt } from '@mui/icons-material';
import { HowItWorks } from 'components/common';
import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]
const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Box className={classes.container}>
      </Box>
      <Box className={classes.headingContainer}>
        <Typography textAlign="center" variant="h2" className={classes.heading}>
          San Francisco's Condo Market
        </Typography>
        <Typography textAlign="center" variant="body1" className={classes.subheading}>
          Make an offer on any unit in any building. List your own property without moving out.
        </Typography>
        <Box className={classes.headerButtons}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            onClick={()=> navigate('/search-building')}
            className={classes.searchButton}
            startIcon={<Search />}
          >
            Search buildings
          </Button>
        </Box>
      </Box>
      <Box className={classes.howitworks}>
        <Container style={{padding: 20}}>
          <Typography textAlign="center" variant="h3" className={classes.hiwHeading}>
            How It Works
          </Typography>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card className={classes.cardHiw}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography textAlign="center" variant="h4" className={classes.hiwSubHeadings}>
                      Bid on any unit
                    </Typography>
                    <ArrowRightAlt onClick={()=> navigate('/search-building')} />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.hiwBody}>
                    Submit a bid on any unit you want, whether it's listed or not. Owners receive offers and negotiate.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" className={classes.hiwLearnMore} onClick={()=> navigate('/search-building')}>Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={classes.cardHiw}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography textAlign="center" variant="h4" className={classes.hiwSubHeadings}>
                      List your own property
                    </Typography>
                    <ArrowRightAlt onClick={()=> navigate('/search-building')} />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.hiwBody}>
                    Set the price you would sell for without moving out, and receive offers directly.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={()=> navigate('/search-building')} size="small" className={classes.hiwLearnMore}>Learn More</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card className={classes.cardHiw}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography textAlign="center" variant="h4" className={classes.hiwSubHeadings}>
                      Become an affiliate
                    </Typography>
                    <ArrowRightAlt onClick={()=> navigate('/search-building')} />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.hiwBody}>
                    Earn from distributing properties on the Condo Market.
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActionBar}>
                  <Button onClick={()=> navigate('/search-building')} size="small" className={classes.hiwLearnMore}>Learn More</Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </Box>
      <Box className={classes.testimonials}>
        {testimonials.map((item)=> {
          return (
            <Card className={classes.cardTestimonials}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                </Box>
                <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionBar}>
                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
              </CardActions>
            </Card>    
          )
        })}
        
      </Box>
    </>
  );
}

export default Home

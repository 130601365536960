import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
	container: {
   backgroundColor: '#ffffff',
   padding: 15,
	},
  testimonials: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    background: '#ffffff',
    padding: '80px 20px',
    marginLeft: -15,
    marginRight: -15,
    overflowX: 'scroll',
  },
  cardTestimonials: {
    minWidth: 300,
    height: 200,
    position: 'relative',
  },
  testimonialsBody: {
    flex: '0 auto',
    fontSize: 16,
    lineHeight: '26px',
    color: '#576d8e',
  },
  cardActionBar: {
    position: 'absolute',
    bottom: 0,
  },
  navLinks: {
  	display: 'flex',
  	flexDirection: 'row',
  	gap: 10,
  	marginBottom: 15,
  	'& a': {
  		textDecoration: 'none',
  		fontSize: 14,
  		'&:hover': {
  			textDecoration: 'none',
  		}
  	},
  	'& .MuiTypography-body1': {
  		lineHeight: 'normal',
  	}
  },
  activeLink: {
  	fontWeight: 'bold',
  },
  divider: {
  	margin: '15px 0 25px 0 !important',
  }
}));
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { PrivateRoute } from 'routes';
import { AuthProvider } from './AuthProvider';
import { Layout, ScrollToTop } from 'components/common'; // Adjust the path as necessary
import {
  HomePage,
  BuyPage,
  SellPage,
  ContactUsPage,
  BuildingsPage,
  BuildingDetails,
  AffiliateProgram,
  Register,
  Login,
  Dashboard,
  PrivacyPolicy,
  TermsOfServices,
  Sitemap,
} from 'pages';
import './index.css';
// import SignupPage from './pages/SignupPage';
// import DashboardPage from './pages/DashboardPage';

function App() {
  return (
    <AuthProvider>
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/buy" element={<BuyPage />} />
          <Route path="/sell" element={<SellPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/about-us" element={<ContactUsPage />} />
          <Route path="/search-building" element={<BuildingsPage />} />
          <Route path="/building-details" element={<BuildingDetails />} />
          <Route path="/building-details/:id" element={<BuildingDetails />} />
          <Route path="/building-details/:id/:unitId" element={<BuildingDetails />} />
          <Route path="/affiliate-program" element={<AffiliateProgram />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-services" element={<TermsOfServices />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </AuthProvider>
  );
}

export default App;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
} from '@material-ui/core';
import {
  Divider,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { HowItWorks } from 'components/common';

import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const PrivacyPolicy = () => {
  const classes = useStyles();
  
  return (
    <Container className={classes.container}>
      <Box>
        <Typography textAlign="left" variant="h3">Privacy Policy</Typography>
        <Divider className={classes.divider} />
        <Typography textAlign="left" variant="body1"><b>Effective Date: july 30, 2024</b></Typography>
        <Typography textAlign="left" variant="body1"><b>Introduction</b></Typography>
        <Typography textAlign="left" variant="body1">Welcome to San Francisco Condo Market, operated by Condo Market, Inc. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our platform.</Typography>
        <ol>
          <li>
            <Typography textAlign="left" variant="body1"><b>Information We Collect</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  Personal Information: When you register for an account, submit bids, or participate in our affiliate program, we may collect personal information such as your name, email address, phone number, and address.

                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Payment Information: When you make payments for our services, we collect payment information, including credit card numbers and billing addresses.

                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Usage Data: We collect information about your interactions with our platform, including IP addresses, browser types, pages visited, and time spent on the platform.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Cookies and Tracking Technologies: We use cookies and similar tracking technologies to enhance your experience on our platform.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>How We Use Your Information</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  To Provide Services: We use your information to operate and maintain the platform, process transactions, and provide customer support.

                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  To Improve Our Platform: We analyze usage data to improve the functionality and performance of our platform.

                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  To Communicate with You: We use your contact information to send you updates, newsletters, and other communications related to our services.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  To Ensure Security: We use your information to protect against fraud, unauthorized transactions, and other liabilities.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b> How We Share Your Information</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  With Service Providers: We may share your information with third-party service providers who assist us in operating the platform, processing payments, and providing customer support.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  With Brokers and Agents: When you submit bids or interact with property listings, we share relevant information with the brokers and agents handling those transactions.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  With Brokers and Agents: When you submit bids or interact with property listings, we share relevant information with the brokers and agents handling those transactions.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Legal Requirements: We may disclose your information if required by law or in response to legal processes, such as a court order or subpoena.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Your Choices</b></Typography>
            <ul>
              <li>
                <Typography textAlign="left" variant="body1">
                  Account Information: You can update your account information at any time by logging into your account and accessing the account settings page.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Marketing Communications: You can opt-out of receiving marketing communications from us by following the unsubscribe instructions provided in those communications.
                </Typography>
              </li>
              <li>
                <Typography textAlign="left" variant="body1">
                  Cookies: You can manage your cookie preferences through your browser settings.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Data Security</b></Typography>
            <Typography textAlign="left" variant="body1">
              We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, and destruction. However, no internet-based platform can be 100% secure, and we cannot guarantee the absolute security of your data.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Data Retention</b></Typography>
            <Typography textAlign="left" variant="body1">
              We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Children's Privacy</b></Typography>
            <Typography textAlign="left" variant="body1">
              Our platform is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Changes to This Privacy Policy</b></Typography>
            <Typography textAlign="left" variant="body1">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform. Your continued use of the platform after any such changes constitutes your acceptance of the new Privacy Policy.
            </Typography>
          </li>
          <li>
            <Typography textAlign="left" variant="body1"><b>Contact Us</b></Typography>
            <Typography textAlign="left" variant="body1">
              If you have any questions about these Terms, please contact us at:
            </Typography>
            <Typography textAlign="left" variant="body1">
              Condo Market, Inc.
            </Typography>
            <Typography textAlign="left" variant="body1">
              <b>info@sanfranciscocondomarket.com</b>
            </Typography>
          </li>
        </ol>
        <Box className={classes.testimonials}>
          {testimonials.map((item)=> {
            return (
              <Card className={classes.cardTestimonials}>
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                    <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                  </Box>
                  <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                    {item.content}
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActionBar}>
                  <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
                </CardActions>
              </Card>    
            )
          })}
        </Box>
        <HowItWorks />
      </Box>
    </Container>
  );
}

export default PrivacyPolicy

import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Box from '@mui/material/Box';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Box component="main" sx={{ mb: 2, pt: 2}}>
        {children}
      </Box>
      <Footer />
    </>
  );
};

export default Layout;

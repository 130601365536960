import { makeStyles } from '@material-ui/core/styles';
import { BuyFooterImg } from 'assets/images';
export const useStyles = makeStyles((theme) => ({
	container: {
    zIndex: -5,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '100%',
    minHeight: 650,
    maxHeight: 650,
    display: 'flex',
    overflow: 'hidden',
    '& img': {
    	objectFit: 'cover',
    	width: '100%',
    },
    [theme.breakpoints.down('md')]: {
    	minHeight: 300,
    	maxHeight: 300,
    },
	},
  testimonials: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    background: '#ffffff',
    padding: '80px 20px',
    marginLeft: 15,
    marginRight: 15,
    overflowX: 'scroll',
  },
  cardTestimonials: {
    minWidth: 300,
    height: 200,
    position: 'relative',
  },
  testimonialsBody: {
    flex: '0 auto',
    fontSize: 16,
    lineHeight: '26px',
    color: '#576d8e',
  },
  cardActionBar: {
    position: 'absolute',
    bottom: 0,
  },
  titles: {
  	fontWeight: '600',
  	marginTop: '10px',
  },
  titlesName: {
  	fontWeight: '700',
  	marginTop: '10px',
  },
  dividerGap: {
  	paddingTop: 20,
  	paddingBottom: 20,
  },
  aboutProperty: {
  	'& p': {
  		lineHeight: '32px',
  		fontSize: 16,
  	},
  	'& ul': {
  		lineHeight: '32px',
  		fontSize: 16,
  	},
  },
  amenitiesProperty: {
  	'& p': {
  		lineHeight: '32px',
  		fontSize: 16,
  	},
  	'& ul': {
  		lineHeight: '32px',
  		fontSize: 16,
  	},
  },
  seeAllPhotos: {
		backgroundColor: 'rgba(24, 30, 40, .8)',
		top: 0,
		right:0,
		left:0,
		bottom:0,
		position: 'absolute',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	seeAllPhotosText: {
		color: '#ffffff',
	},
	pointer: {
		cursor: 'pointer',
	},
	carImgList: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
	},
	viewAllunits: {
		width: '100%',
		marginTop: 10,
		backgroundColor: '#087abd',
		padding: 10,
		color: '#ffffff',
		fontWeight: 'bold',
    textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: '#087abd',
		},
	},
	tabs: {
		backgroundColor: '#ffffff',
		borderTopRightRadius: 10,
		borderTopLeftRadius: 10
	},
	tabItem: {
		width: '50%',
		maxWidth: '50%',
		color: '#000000',
		'& .Mui-selected': {
			color: '#087abd',
		},
	},
	tabListItem: {
		borderBottomRightRadius: 10,
		borderBottomLeftRadius: 10,
		border: '1px solid #cccccc',
		borderTop: 'none',
		width: '100%',
		position: 'relative',
	},
	buyingitemTab: {
		padding: '10px 20px',
		color: '#90a1bb',
		backgroundColor: '#ffffff',
		fontWeight: 'bold',
    textTransform: 'math-auto',
		'&:hover': {
			backgroundColor: '#ffffff',
		},
	},
	activeTab: {
		padding: '10px 20px',
		borderRadius: 4,
		color: '#087abd',
		backgroundColor: '#f0f2f5',
		fontWeight: 'bold',
    textTransform: 'math-auto',
		'&:hover': {
			backgroundColor: '#f0f2f5',
		},
	},
	units: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		border: '1px solid #cccccc',
		padding: 10,
		minWidth: 300,
    minHeight: 200,
    position: 'relative',
		'&:hover': {
			border: '1px solid #087abd',
		},
	},
	unitFig: {
		display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 5, alignItems: 'center'
	},
	unitName: {
    flex: '0 auto',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
	},
	itemFigText: {
		color: '#181e28',
		fontSize: 14,
    lineHeight: '26px',
	},
	lastsoldprice: {
		marginBottom: 10,
	},
	unitValue: {
    marginBottom: 0,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '26px',
	},
	  sellerForm: {
    marginTop: 60,
    marginBottom: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  sellerFormTitle: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 38,
    fontWeight: 700,
    lineHeight: '44px'
  },
  formButton: {
    padding: '10px 20px',
    backgroundColor: '#3898ec',
    color: '#ffffff',
    fontWeight: 'bold',
    marginTop: 20,
    fontSize: 14,
    textTransform: 'capitalize',
    width: '100%'
  },
  imgCarList: {
  	width: '100%',
  	height: 'auto',
  	display: "block",
    [theme.breakpoints.down('md')]: {
    	height: 300,
    },
  },
  ownerPrice: {
  	color: 'green',
  },
}));

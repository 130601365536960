import * as React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useStyles } from './styles';

function Loader() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
    	<Box className={classes.loader}>
    		<Typography variant="body1" className={classes.loaderText}><b>Processing...</b></Typography>
    	</Box>
    </Box>
  );
}
export default Loader;
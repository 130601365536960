import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Divider,
  Grid,
  Link,
} from '@mui/material';
import { Logo } from 'assets/images';
import { useStyles } from './styles';

const explore = ['Buy', 'Sell', 'FAQ'];
const company = ['About us', 'Contact us'];

function Footer() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNav = (e) => {
    navigate(`/${e.target.innerText.toLowerCase().replace(/ /g,"-")}`, { replace: true });
  };
  return (
    <Box className={classes.footer}>
    	<Container>
    		<img src={Logo} className={classes.img} alt="logo" />
    		<Divider className={classes.footerDivider} />

    		<Grid container spacing={2}>
    			<Grid item xs={12} md={5}>
    			<Typography textAlign="left" variant="body1" className={classes.footerHeading}>
	          A streamlined process to buy and sell condominiums in San Francisco
	        </Typography>
    			</Grid>
    			<Grid item xs={12} md={2}>
    				<Typography textAlign="left" variant="body1" className={classes.footerHeading}>
	          	Explore
	        	</Typography>
	        	{explore.map((item)=>{
	        			return (
	        				<Box style={{marginBottom: 20, marginTop: 20}} key={item}>
		        				<Typography textAlign="left" variant="body1" className={classes.footerLinks} onClick={handleNav}>
					          	{item}
					        	</Typography>
				        	</Box>
        				)
	        	})}
    			</Grid>
    			<Grid item xs={12} md={2}>
    				<Typography textAlign="left" variant="body1" className={classes.footerHeading}>
	          	Company
	        	</Typography>
	        	{company.map((item)=>{
	        			return (
	        				<Box style={{marginBottom: 20, marginTop: 20}} key={item}>
		        				<Typography textAlign="left" variant="body1" className={classes.footerLinks} onClick={handleNav}>
					          	{item}
					        	</Typography>
				        	</Box>
        				)
	        	})}
    			</Grid>
    			<Grid item xs={12} md={3}>
    				<Typography textAlign="left" variant="body1" className={classes.footerHeading}>
	          	Contact
	        	</Typography>
	        	<Box style={{marginBottom: 20, marginTop: 20}}>
      				<Typography textAlign="left" variant="body1" className={classes.footerLinks}>
		          	+1 415 691 9272
		        	</Typography>
	        	</Box>
	        	<Box style={{marginBottom: 20, marginTop: 20}}>
      				<Typography textAlign="left" variant="body1" className={classes.footerLinks}>
		          	tim@mcmullen.properties
		        	</Typography>
	        	</Box>
    			</Grid>
    		</Grid>

    		<Divider className={classes.footerDivider} />
    		<Grid item xs={12} md={12} className={classes.footerlinksParent}>
	    		<Typography textAlign="left" variant="body1" className={classes.footerLastText}>
	        	© 2024 Label Marketplaces. All Rights Reserved.
					</Typography>
					&nbsp;<Link href="/sitemap" color="inherit">Sitemap</Link>,
					&nbsp;<Link href="/privacy-policy" color="inherit">Privacy Policy</Link>&nbsp;
					&nbsp;<Typography textAlign="left" variant="body1" className={classes.footerLastText}>and</Typography>&nbsp;
					<Link href="/terms-of-services" color="inherit">Terms of Services</Link>
				</Grid>
    	</Container>
    </Box>
  );
}
export default Footer;
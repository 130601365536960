import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  CssBaseline,
  Container,
  Avatar,
  TextField,
  Link,
  Button,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RedirectIfAuthenticated from 'RedirectIfAuthenticated';
import { HowItWorks } from 'components/common';

const Register = () => {
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
 	const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null,
    });
  };


   const validateForm = () => {
    let isValid = true;
    let errors = { name: '', email: '', password: '' };

    if (formData.name.trim() === '') {
      errors.name = 'Name is required';
      isValid = false;
    }
    if (formData.email.trim() === '') {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }
    if (formData.password.trim() === '') {
      errors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
      isValid = false;
    }

    if (formData.password_confirmation.trim() === '') {
      errors.password_confirmation = 'Confirm Password is required';
      isValid = false;
    } else if (formData.password !== formData.password_confirmation) {
      errors.password_confirmation = 'Confirm Password should match with password';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: formData }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          setErrors(errorData.errors);
          return;
        }

        const data = await response.json();
        setSuccessMessage('Registration successful!');
        if (data.token) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          window.location.reload();
        } else {
          alert('Not able to create account at this point');
        }
        // Save token to localStorage or state for further use
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };


  return (
    <>
      <RedirectIfAuthenticated />
    	<Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 700,
            justifyContent: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                  onChange={handleChange}
                  value={formData.firstName}
                  error={errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  value={formData.email}
                  error={errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  value={formData.password}
                  error={errors.password}
                  helperText={errors.password}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password_confirmation"
                  label="Confirm Password"
                  type="password"
                  id="password_confirmation"
                  autoComplete="off"
                  onChange={handleChange}
                  value={formData.password_confirmation}
                  error={errors.password_confirmation}
                  helperText={errors.password_confirmation}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: 50 }}
            >
              Submit
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <HowItWorks />
    </>
  );
}

export default Register

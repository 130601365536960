import React, {useState} from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
} from '@material-ui/core';
import {
	TextField,
  Divider,
  Checkbox,
  FormControlLabel,
  Alert
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { BuyImg } from 'assets/images';
import { FiberManualRecord, Check } from '@mui/icons-material';
import { Loader, HowItWorks } from 'components/common';

import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const feedsSteps = [
	{
		key: '01',
		title: 'Become an affiliate',
		body: "Fill out the form below to be approved for the affiliate program."
	},
	{
		key: '02',
		title: 'Create an account',
		body: "Affiliates create an account to track sharing, successful buyer/seller sign ups and toggle payment settings."
	},
	{
		key: '03',
		title: 'Receive unique links for every listing',
		body: "Every condo owner that Sets Their Price gives affiliates the chance to share & earn. Every listing has a unique link for you to share."
	},
	{
		key: '04',
		title: 'Share with the world and get paid',
		body: "The more links you share, the better chance you have of finding buyers & sellers. Affiliates earn from the seller when their property sells. Sign up to learn more."
	}
]
const AffiliateProgram = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    phone: '',
    location: '',
    license_ca: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/affiliates/join`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      // setData(result);
      successStatus()
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  
  const successStatus = async(e) => {
    setSuccess(true);
    setFormData({
      email: '',
	    name: '',
	    phone: '',
	    location: '',
	    license_ca: false
    })
    setTimeout(function(){
      setSuccess(false);
    },1500)
  }
  return (
    <>
    	<Box className={classes.container}>
    		<img src={BuyImg} alt="Buyer"/>
    	</Box>
    	<Card className={classes.offer}>
        <CardContent className={classes.offerContent}>
          <Box className={classes.navLinks}>
          	<Link href="/" color="inherit">Home</Link>
          	<Typography textAlign="center" variant="body1">
            	/
          	</Typography>
          	<Link href="#" color="inherit" className={classes.activeLink}>Affiliate program</Link>
          </Box>
          <Typography textAlign="center" variant="h1" className={classes.offerTitle}>
            Earn by distributing Condo Market listings to buyers
          </Typography>

          <Typography textAlign="center" variant="body1" className={classes.offerBody}>
            Condo owners need your help to make sure their listings reach prospective buyers. Our affiliate program allows you to earn for sharing unique links for each listings to potential buyers. Sign up today and start sharing with your sphere!
          </Typography>
        </CardContent>
      </Card>

      <Box className={classes.feedsStepsParent}>
      	<Container style={{padding: 20}}>
          <Typography textAlign="center" variant="h3" className={classes.feedsStepsHeading}>
            How It Works
          </Typography>
          <Divider className={classes.feedsStepsDivider} />
          <br/>
          <Grid container spacing={2}>
		        {feedsSteps.map((item)=> {
		          return (
		            <Grid item xs={12} md={3}>
			            <Box className={classes.feedsSteps}>
			              <Box className={classes.feedsStepsBodyParent}>
			                <Typography textAlign="center" variant="h1" className={classes.feedsStepsKey}>
			                  {item.key}<FiberManualRecord />
			                </Typography>
			                <Typography textAlign="center" variant="h3" className={classes.feedsStepsTitle}>
			                  {item.title}
			                </Typography>
			                <Typography textAlign="center" variant="body1" className={classes.feedsStepsBody}>
			                  {item.body}
			                </Typography>
			              </Box>
			            </Box>
		            </Grid>
		          )
		        })}
	        </Grid>
        </Container>
      </Box>

      <Box className={classes.propertyContactUs}>
				<Box className={classes.sellerForm}>
	        <Typography textAlign="center" variant="h3" className={classes.sellerFormTitle}>
	          Become an affiliate partner
	        </Typography>
	        <Box
	          component="form"
	          sx={{
	            '& .MuiTextField-root': { marginTop: 20, width: '100%' },
	          }}
	          noValidate
	          autoComplete="off"
	        >
            
	          <TextField
	            required
	            fullWidth
	            name="name"
	            label="Name"
	            type="text"
	            id="sname"
	            onChange={handleChange}
	            value={formData.name}
	          />

	          <TextField
	            required
	            fullWidth
	            name="email"
	            label="Email"
	            type="email"
	            id="semail"
	            onChange={handleChange}
	            value={formData.email}
	          />


	          <TextField
	            required
	            fullWidth
	            name="phone"
	            label="Phone"
	            type="number"
	            id="sphone"
	            onChange={handleChange}
	            value={formData.phone}
	          />

	          <TextField
	            required
	            fullWidth
	            name="location"
	            label="Current Location"
	            type="text"
	            id="slocation"
	            onChange={handleChange}
	            value={formData.location}
	          />

	          <FormControlLabel control={<Checkbox checked={formData.license_ca} onChange={()=>setFormData({...formData, license_ca: !formData.license_ca})} />} label="Do you hold a real estate license in California?" />
        	</Box>
	        <Box
	          component="form"
	          noValidate
	          autoComplete="off"
	          
	        >
        		<Button variant="contained" className={classes.formButton} onClick={handleSubmit}>Submit Application</Button>
        	</Box>
      	</Box>
      	<Divider />
    	</Box>

	  	<Box className={classes.testimonials}>
        {testimonials.map((item)=> {
          return (
            <Card className={classes.cardTestimonials}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                </Box>
                <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionBar}>
                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
              </CardActions>
            </Card>    
          )
        })}
        
      </Box>
      <HowItWorks />
      
    </>
  );
}

export default AffiliateProgram

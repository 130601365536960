import React, { useContext } from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from '@mui/material';
import { Button as CoreButton } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { LogoHeader } from 'assets/images';
import { AuthContext } from 'AuthProvider';
import { useStyles } from './styles';

const pages = ['Search Building', 'Buy', 'Sell', 'Affiliate Program'];
const settings = ['Account', 'Dashboard', 'Logout'];

function Header() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { currentUser, token } = useContext(AuthContext);
  // eslint-disable-next-line
  const [loggedIn, setLoggedIn] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [headerClass, setHeaderClass] = useState('header');
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    if (window.location.pathname !== "/") {
      setHeaderClass('whiteHeader')
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    navigate(`/${e.target.innerText.toLowerCase().replace(/ /g,"-")}`, { replace: true });
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const logout = async() => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/logout`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: token }),
      });

      const data = await response.json();
      if (response.status == 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("currentUser");
        window.location.reload();
      } else {
        alert('Not able to create account at this point');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <AppBar position={isSticky ? "sticky" : "static"} className={isSticky ? classes.stickeyHeader : classes[headerClass]}>
      <Container maxWidth="xl" className={classes.container}>
        <Toolbar disableGutters>
          <img src={LogoHeader} className={classes.logo} alt="logo" onClick={()=> navigate('/')}/>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        
          <Box sx={{ marginLeft: 10, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: (isSticky || headerClass === 'whiteHeader') ? '#000000' : 'white', display: 'block', textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {loggedIn ? <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> : <Box className={classes.rightNavBtns}>
            {currentUser ? <>
              <Typography onClick={()=> navigate('/dashboard')} textAlign="center" style={{ my: 2, color: (isSticky || headerClass === 'whiteHeader') ? '#000000' : 'white', display: 'block', textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer', fontSize: '0.875rem'}}>Dashboard ({currentUser.name})</Typography>
              <Typography onClick={()=> logout()} textAlign="center" style={{ my: 2, color: 'red', display: 'block', textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer', fontSize: '0.875rem'}}>Logout</Typography>
            </> : <>
              <Typography onClick={()=> navigate('/login')} textAlign="center" style={{ my: 2, color: (isSticky || headerClass === 'whiteHeader') ? '#000000' : 'white', display: 'block', textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer', fontSize: '0.875rem'}}>Login</Typography>
              <Typography onClick={()=> navigate('/register')} textAlign="center" style={{ my: 2, color: (isSticky || headerClass === 'whiteHeader') ? '#000000' : 'white', display: 'block', textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer', fontSize: '0.875rem' }}>Register</Typography>
            </>}

            <CoreButton
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              onClick={()=> navigate('/contact-us')}
              className={classes.contactUsButton}
            >
              Contact
            </CoreButton>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
import { makeStyles } from '@material-ui/core/styles';
import { HowItWorks, BuyFooterImg } from 'assets/images';
export const useStyles = makeStyles((theme) => ({
    heading: {
    fontWeight: 'bold',
  },
  subheading: {
    marginBottom: 0,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '96px',
  },
  hiwButton: {
    padding: '16px 32px',
    borderRadius: 100,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 20,
    background: '#000000',
    color: '#ffffff'
  },
  howitworks: {
    backgroundImage: `url("${HowItWorks}")`,
    minHeight: 200,
    backgroundSize: '100%',
    marginLeft: -15,
    marginRight: -15,
    paddingTop: 30,
    paddingBottom: 30,
  },
  hiwHeading: {
    marginBottom: 0,
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '40px',
  },
  hiwSubHeadings: {
    flex: '0 auto',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    marginBottom: 10
  },
  hiwBody: {
    flex: '0 auto',
    fontSize: 16,
    lineHeight: '26px',
    color: '#576d8e',
  },
  hiwLearnMore: {
    textTransform: 'capitalize',
    letterSpacing: '.2px',
    marginBottom: 0,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    textDecoration: 'underline'
  },
  cardHiw: {
    minHeight: 200,
    position: 'relative',
  },
  cardActionBar: {
    position: 'absolute',
    bottom: 0,
  },
  navLinks: {
  	display: 'flex',
  	flexDirection: 'row',
  	gap: 10,
  	marginBottom: 15,
  	'& a': {
  		textDecoration: 'none',
  		fontSize: 14,
  		'&:hover': {
  			textDecoration: 'none',
  		}
  	},
  	'& .MuiTypography-body1': {
  		lineHeight: 'normal',
  	}
  },
  activeLink: {
  	fontWeight: 'bold',
  },
  propertyContactUs:{
  	backgroundColor: '#ffffff',
  	marginLeft: -15,
  	marginRight: -15,
  	paddingTop: 20
  },
  propertyContactUsForm: {
  	backgroundColor: '#ffffff',
  	padding: 20,
  	minHeight: 200,
  	borderRadius: 10,
  },
  propertyContactusLink: {
  	backgroundImage: `url("${BuyFooterImg}")`,
    minHeight: 200,
    width: '100%',
    padding: 0,
    position: 'relative',
    objectFit: 'cover',
    borderRadius: 10,
    backgroundPosition: '50%',
    backgroundSize: 'cover',
  },
}));
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
} from '@material-ui/core';
import {
  Divider,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { FiberManualRecord, Search } from '@mui/icons-material';
import { BuyImg } from 'assets/images';
import { HowItWorks } from 'components/common';

import { useStyles } from './styles'; // Adjust the path as necessary
const testimonials = [
  {name: 'David, Buyer', content: 'Revolutionized the way you buy for a condo. Everything is for sale for the right price.'},
  {name: 'Dan, Seller', content: '“I set the price I would sell my unit for, and received an offer at that price.”'},
  {name: 'Yael, Buyer', content: '“I bid on a unit that wasn\'t on the market and the owner responded, we eventually came”'},
  {name: 'Emily, Buyer', content: 'We bid on the unit we wanted rather than waiting for it to hit the market, and bought without'},
  {name: 'Sam, Buyer', content: 'The first condo we offered on we won.'},
]

const feedsSteps = [
	{
		key: '01',
		title: 'Select a building',
		body: "Search buildings for local favorites. If you cant find the building you're looking for - send us a text and we'll add it to our list."
	},
	{
		key: '02',
		title: 'Submit an offer on a unit',
		body: "Select the unit you would like to purchase and submit an offer. All offers require pre-approval or proof of funds, and are written on current San Francisco Residential Purchase Contracts."
	},
	{
		key: '03',
		title: 'Be notified when the owner engages',
		body: "Homeowners receive a unique link to your offer with the ability to Accept, Negotiate or Reject. You will be notified immediately upon their decision."
	},
	{
		key: '04',
		title: 'Acceptance, negotiation, and inspection periods',
		body: "All site-unseen offers are subject to an inspection period. A date will be set to conduct inspections, navigate property condition, then  ratify the offer or re-negotiate with new terms."
	}
]
const Buy = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
    	<Box className={classes.container}>
    		<img src={BuyImg} alt="Buyer"/>
    	</Box>
    	<Card className={classes.offer}>
        <CardContent className={classes.offerContent}>
          <Box className={classes.navLinks}>
          	<Link href="/" color="inherit">Home</Link>
          	<Typography textAlign="center" variant="body1">
            	/
          	</Typography>
          	<Link href="#" color="inherit" className={classes.activeLink}>Buy</Link>
          </Box>
          <Typography textAlign="center" variant="h1" className={classes.offerTitle}>
            Write an offer on any unit in any building in San Francisco
          </Typography>

          <Typography textAlign="center" variant="body1" className={classes.offerBody}>
            Buyers can submit an offer on any unit in any building, removing the major
            limiting factor of buyers having to wait until a condo is listed on the market,
            then compete against countless other buyers and drive up the price. Homeowners 
            will receive notification that their unit has received an offer and decide if 
            now is the right time to entertain it, or not.
          </Typography>
        </CardContent>
      </Card>

      <Box className={classes.feedsStepsParent}>
      	<Container style={{padding: 20}}>
          <Typography textAlign="center" variant="h3" className={classes.feedsStepsHeading}>
            How It Works
          </Typography>
          <Divider className={classes.feedsStepsDivider} />
          <br/>
          <Grid container spacing={2}>
		        {feedsSteps.map((item)=> {
		          return (
		            <Grid item xs={12} md={3}>
			            <Box className={classes.feedsSteps}>
			              <Box className={classes.feedsStepsBodyParent}>
			                <Typography textAlign="center" variant="h1" className={classes.feedsStepsKey}>
			                  {item.key}<FiberManualRecord />
			                </Typography>
			                <Typography textAlign="center" variant="h3" className={classes.feedsStepsTitle}>
			                  {item.title}
			                </Typography>
			                <Typography textAlign="center" variant="body1" className={classes.feedsStepsBody}>
			                  {item.body}
			                </Typography>
			              </Box>
			            </Box>
		            </Grid>
		          )
		        })}
	        </Grid>
        </Container>

        <Box className={classes.searchButtonParent}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            onClick={()=> navigate('/search-building')}
            className={classes.searchButton}
            startIcon={<Search />}
          >
            Search buildings
          </Button>
        </Box>

      </Box>
      
      <Box className={classes.testimonials}>
        {testimonials.map((item)=> {
          return (
            <Card className={classes.cardTestimonials}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                </Box>
                <Typography textAlign="center" variant="body1" className={classes.testimonialsBody}>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionBar}>
                <Button size="small" className={classes.hiwLearnMore}>- {item.name}</Button>
              </CardActions>
            </Card>    
          )
        })}
        
      </Box>
      <HowItWorks />
    </>
  );
}

export default Buy

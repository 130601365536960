import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import {
  Box,
} from '@material-ui/core';
import {
	Tabs,
	Tab,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableContainer,
	TableRow,
	TablePagination
}from '@mui/material';
import { Loader } from 'components/common';
import { AuthContext } from 'AuthProvider';
import { useStyles } from './styles'; // Adjust the path as necessary

const columns = [
  { id: 'name', label: 'Building Name', minWidth: 170 },
  { id: 'address', label: 'Unit Address', minWidth: 170 },
  { id: 'area', label: 'Unit Area (sq. ft.)', minWidth: 170 },
  { id: 'bathrooms', label: 'Bathrooms', minWidth: 170 },
  { id: 'bedrooms', label: 'Bedrooms', minWidth: 170 },
  { id: 'price', label: 'Your Selling Price', minWidth: 170 },
];

const buyColumns = [
  { id: 'name', label: 'Building name'},
  { id: 'address', label: 'Unit address'},
  { id: 'price', label: 'Your Offer Price'},
  { id: 'action', label: 'Action'},
];


const affiliateColumns = [
  { id: 'link', label: 'Link (Token)', minWidth: 170 },
  { id: 'name', label: 'Building name', minWidth: 170 },
  { id: 'address', label: 'Unit address', minWidth: 170 },
  { id: 'opens', label: 'Total opens', minWidth: 170 },
  { id: 'clicks', label: 'Total clicks', minWidth: 170 },
  { id: 'action', label: 'Action', minWidth: 170 },
];

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


const Dashboard = () => {
  const classes = useStyles();
  
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  const [sellData, setSellData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buyData, setBuyData] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchData = async (query) => {
  	const url = `${process.env.REACT_APP_API_URL}/api/v1/dashboard`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (!response.ok) {
      return;
    }

    const data = await response.json();
    setSellData(data.sellcondo);
    setBuyData(data.buycondo);
    setAffiliates(data.affiliates);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function CustomTabPanel(props) {
	  const { children, value, index, ...other } = props;

	  return (
	    <div
	      role="tabpanel"
	      hidden={value !== index}
	      id={`simple-tabpanel-${index}`}
	      aria-labelledby={`simple-tab-${index}`}
	      {...other}
	    >
	      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
	    </div>
	  );
	}

	CustomTabPanel.propTypes = {
	  children: PropTypes.node,
	  index: PropTypes.number.isRequired,
	  value: PropTypes.number.isRequired,
	};

	function a11yProps(index) {
	  return {
	    id: `simple-tab-${index}`,
	    'aria-controls': `simple-tabpanel-${index}`,
	  };
	}	

  return (
    <Box className={classes.container}>
      {loading && <Loader />}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Selling History" {...a11yProps(0)} />
          <Tab label="Buying History" {...a11yProps(1)} />
          <Tab label="Affiliate Links" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
		      <TableContainer sx={{ maxHeight: 840 }}>
		        <Table stickyHeader aria-label="sticky table">
		          <TableHead>
		            <TableRow>
		              {columns.map((column) => (
		                <TableCell
		                  key={column.id}
		                  align={column.align}
		                  style={{ minWidth: column.minWidth }}
		                >
		                  {column.label}
		                </TableCell>
		              ))}
		            </TableRow>
		          </TableHead>
		          <TableBody>
		            {sellData
		              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		              .map((row) => {
		                return (
		                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
		                    {columns.map((column) => {
		                      const value = column.id == 'price' ? USDollar.format(row[column.id]) : row[column.id];
		                      if(column.id == 'address') {
	                      		return (
			                        <TableCell key={column.id} align={column.align}>
			                          <a href="">
				                          {column.format && typeof value === 'number'
			                            ? column.format(value)
			                            : value}
		                            </a>
			                        </TableCell>
			                      );
	                      	} else {
		                      return (
		                        <TableCell key={column.id} align={column.align}>
		                          {column.format && typeof value === 'number'
		                            ? column.format(value)
		                            : value}
		                        </TableCell>
		                      );
		                    	}
		                    })}
		                  </TableRow>
		                );
		              })}
		          </TableBody>
		        </Table>
		      </TableContainer>
		      <TablePagination
		        rowsPerPageOptions={[10, 25, 100]}
		        component="div"
		        count={sellData.length}
		        rowsPerPage={rowsPerPage}
		        page={page}
		        onPageChange={handleChangePage}
		        onRowsPerPageChange={handleChangeRowsPerPage}
		      />
		    </Paper>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
		      <TableContainer sx={{ maxHeight: 840 }}>
		        <Table stickyHeader aria-label="sticky table">
		          <TableHead>
		            <TableRow>
		              {buyColumns.map((column) => (
		                <TableCell
		                  key={column.id}
		                  align={column.align}
		                  style={{ minWidth: column.minWidth }}
		                >
		                  {column.label}
		                </TableCell>
		              ))}
		            </TableRow>
		          </TableHead>
		          <TableBody>
		            {buyData
		              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		              .map((row) => {
		                return (
		                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
		                    {buyColumns.map((column) => {
		                      const value = column.id == 'price' ? USDollar.format(row[column.id]) : row[column.id];
		                      return (
		                        <TableCell key={column.id} align={column.align}>
		                          {column.format && typeof value === 'number'
		                            ? column.format(value)
		                            : value}
		                        </TableCell>
		                      );
		                    })}
		                  </TableRow>
		                );
		              })}
		          </TableBody>
		        </Table>
		      </TableContainer>
		      <TablePagination
		        rowsPerPageOptions={[10, 25, 100]}
		        component="div"
		        count={sellData.length}
		        rowsPerPage={rowsPerPage}
		        page={page}
		        onPageChange={handleChangePage}
		        onRowsPerPageChange={handleChangeRowsPerPage}
		      />
		    </Paper>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
		      <TableContainer sx={{ maxHeight: 840 }}>
		        <Table stickyHeader aria-label="sticky table">
		          <TableHead>
		            <TableRow>
		              {affiliateColumns.map((column) => (
		                <TableCell
		                  key={column.id}
		                  align={column.align}
		                  style={{ minWidth: column.minWidth }}
		                >
		                  {column.label}
		                </TableCell>
		              ))}
		            </TableRow>
		          </TableHead>
		          <TableBody>
		            {affiliates
		              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		              .map((row) => {
		                return (
		                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
		                    {affiliateColumns.map((column) => {
		                      const value = row[column.id];
		                      if(column.id === 'link') {
		                      	return (
			                        <TableCell key={column.id} align={column.align}>
			                          <a href={value} target="_blank">{row['token']}</a>
			                        </TableCell>
		                        );
		                      }
		                      return (
		                        <TableCell key={column.id} align={column.align}>
		                          {column.format && typeof value === 'number'
		                            ? column.format(value)
		                            : value}
		                        </TableCell>
		                      );
		                    })}
		                  </TableRow>
		                );
		              })}
		          </TableBody>
		        </Table>
		      </TableContainer>
		      <TablePagination
		        rowsPerPageOptions={[10, 25, 100]}
		        component="div"
		        count={sellData.length}
		        rowsPerPage={rowsPerPage}
		        page={page}
		        onPageChange={handleChangePage}
		        onRowsPerPageChange={handleChangeRowsPerPage}
		      />
		    </Paper>
      </CustomTabPanel>
    </Box>
  );
}

export default Dashboard
